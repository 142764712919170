.contactContainer {
  font-size: 3rem;
  margin-left: 2rem;
  margin-top: 3rem;
}

.Items {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  img {
    width: 2rem;
    margin-right: 1rem;
  }
}
.details {
  display: flex;
  justify-content: space-between;
  a {
    color: #f5f5f5;
    &:visited {
      color: #f5f5f5;
    }
  }
}

.downloadFile {
  font-size: 3rem;
  margin-right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 10rem;
  }
  a {
    font-size: 2rem;
  }
}
