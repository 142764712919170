.dark {
  --background: #1b1b1c;
  --fontColor: #f5f5f5;
}
.light {
  --background: #f5f5f5;
  --fontColor: #1b1b1c;
}

$fontSize: 6rem;

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(74, 74, 74);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
