@import url('https://fonts.googleapis.com/css2?family=Baloo+Thambi+2:wght@400;500;600;700;800&family=Karla:wght@200;300;400;500;700;800&family=Poppins:wght@100&display=swap');
@import './styles/theme.scss';
body {
  margin: 0;
  font-family: 'Baloo Thambi 2', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #f5f5f5;
  user-select: none;
  user-zoom: unset;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
