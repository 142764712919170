.viewport {
  /* width: 100vw; */
  height: 100vh;
  background-color: #1b1b1c;
  // background: linear-gradient(-45deg, #430d27, #582233, #713045, #000000);
  background: linear-gradient(-45deg, #310f55, #56107e, #63233a, #524e08);
  // background: linear-gradient(-45deg, rgb(227, 49, 79), rgb(61, 22, 89));
  // background: linear-gradient(
  //   -45deg,
  //   rgb(138, 35, 135),
  //   rgb(233, 64, 87),
  //   rgb(242, 113, 33)
  // );
  // background: linear-gradient(-45deg, rgb(58, 97, 134), rgb(137, 37, 62));
  background-size: 400% 400%;
  animation: gradient 20s ease infinite;
  position: fixed;
  z-index: -1;
}
.scrollContainer {
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}
section {
  height: 100vh;
  /* width: 100vw; */
  scroll-snap-align: end;
}

body {
}
@mixin sections {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.LogoContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > span:first-child {
    margin-top: 25rem;
  }
  & > span {
    font-size: 6rem;
  }
  & > .designation {
    font-size: 2rem;
  }
}
.scolltoKnow {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;

  & > span {
    margin-bottom: 1rem;
  }
  & > div {
    height: 6rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-left: 1px;
    border-left: 1px solid;
    border-color: #f5f5f5;
  }
  & > img {
    width: 5rem;
    height: 5rem;
  }
}
.Logo {
  width: 15rem;
}

.quoteContainer {
  @include sections();
  font-size: 4rem;
  text-align: center;
  padding: 5rem;
}
.CompanyName {
  @include sections();
  padding: 10rem;
  padding-top: 0rem;
  & > img {
    height: 25rem;
  }
  & > div {
    font-size: 4rem;
    width: 40%;
  }
}
.about {
  @include sections();
  font-size: 2.5rem;
  & > div:first-child {
    background-color: rgba(118, 118, 118, 0.255);
    border-radius: 5rem;
    margin-left: 2rem;
  }
  & > div {
    width: 35%;
    padding: 3rem;
  }
}
.openStack {
  @include sections();
  align-items: flex-start;
  font-size: 3rem;
  & > div {
    padding: 4rem;
  }
}
.hobbies {
  @include sections();

  font-size: 3rem;
  & > div {
    padding: 4rem;
    width: 30%;
  }
  & ul {
    font-size: 2rem;
  }
}
// #Logo line circle {
//   animation: checkmark-animation 1s ease-out forwards;
//   stroke-dasharray: 400;
//   stroke-dashoffset: 400;
//   stroke: #cfd8dc;
//   transform-origin: center;
// }

// @keyframes checkmark-animation {
//   40% {
//     transform: scale(1);
//   }
//   55% {
//     stroke: #cfd8dc;
//     transform: scale(1.2);
//   }
//   70% {
//     transform: scale(1);
//   }
//   100% {
//     stroke-dashoffset: 0;
//     transform: scale(1);
//     stroke: #21b587;
//   }
// }

circle {
  fill: #0000002b;
}

// polyline {
//   stroke-dasharray: 1000;
//   stroke-dashoffset: 1000;
//   animation: dash 3s linear forwards;
//   animation-delay: 1s;
// }

// circle {
//   stroke-dasharray: 1000;
//   stroke-dashoffset: 1000;
//   fill: #39383800;
//   animation: fillAnime 3s linear forwards;
//   animation-delay: 1s;
// }

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes fillAnime {
  to {
    stroke-dashoffset: 0;
    fill: #0000002b;
  }
}
.info {
  width: 100%;
  text-align: center;
  color: #f5f5f555;
  font-size: 1.5rem;
}
.EnterClass {
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100%;
  backdrop-filter: blur(15px);
  z-index: 999;
  justify-content: center;
  align-items: center;

  & > div {
    font-size: 2rem;
    border: 5px solid;
    border-radius: 50%;
    width: 15rem;
    height: 15rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #00000073;
    transform: scale(1);
    transition: 1s;
    &:hover {
      transform: scale(1.25);
    }
  }
}
.Equalizer {
  position: fixed;
  right: 1rem;
  top: 1rem;
  width: 24px;
  height: 24px;
  fill: #f5f5f5;
}
