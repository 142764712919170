.Container {
  display: flex;
  justify-content: space-between;
  height: 98vh;
}
.ThankYou {
  width: 25%;
  font-size: 5rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 2rem;
}
.credits {
  width: 30%;
  font-size: 2rem;
  text-align: center;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .heading {
    font-size: 3rem;
  }
}
