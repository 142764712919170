@mixin arrows($side) {
  width: 1rem;
  height: 1rem;
  background-color: rgba(118, 118, 118, 0.255);
  padding: 1rem;
  border-radius: 50%;
  position: absolute;
  #{$side}: 10px;
  top: calc(50% - 1rem);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
}
.wrapper {
  position: relative;
  & > .lessThan {
    @include arrows(left);
  }
  & > .greaterThan {
    @include arrows(right);
  }
}

.projectContainer {
  display: flex;
  overflow-x: hidden;
  &:hover {
    overflow-x: auto;
  }
  margin-left: 4rem;
  margin-right: 4rem;
}
.projTile {
  min-width: 20rem;
  margin: 2rem;
  background-color: rgba(118, 118, 118, 0.255);
  border-radius: 20px;
  padding: 15px;
  backdrop-filter: blur(10px);
  & > .title {
    font-size: 1.5rem;
  }
  & > .desc {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  & > .techStack {
    font-size: 1rem;
  }
}
