.Conatiner {
  position: fixed;
  display: flex;
  bottom: 1rem;
  left: 2rem;
  z-index: 99;
  img {
    width: 2rem;
    margin-right: 1rem;
    transition: 1s;
    opacity: 0.5;
    transform: scale(1);
    &:hover {
      opacity: 1;
      transform: scale(1.25);
    }
  }
}
